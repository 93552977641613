import {Component} from '@angular/core'
import {MatButtonModule} from '@angular/material/button'
import {MatIconModule} from '@angular/material/icon'
import {MatMenuModule} from '@angular/material/menu'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [MatButtonModule, MatMenuModule, MatIconModule, LogoComponent]
})
export class HeaderComponent {
  protected readonly canUseTestingTools = !environment.production

  constructor(public configService: ConfigService) {
  }
}
