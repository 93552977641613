import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {ADMIN_MODULE, LOGIN_PATH} from './application/types'
import {LoginComponent} from './components/login/login.component'

export const routes: Routes = [
  {
    path: ADMIN_MODULE,
    loadChildren: () => import('./admin/admin.routes'),
    canActivate: [authGuard]
  },
  {
    path: LOGIN_PATH,
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: 'admin',
    pathMatch: 'full'
  }
]
